import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {changeExceptionAction, changePoolsAction, getStatusAction} from "../redux/pageSlice";
import {ActionType, EditableProTable, ProColumns, ProFormInstance,} from '@ant-design/pro-components';
import {CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {StatusType} from "../utils/types";
import {Form, Input, Modal} from "antd-mobile";
import {Button, InputNumber, Space} from "antd";
import {useParams} from "react-router-dom";

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #fafafa;
    padding: 20px 0;
    border-radius: 12px;

    .ant-btn {
        margin: 0 20px;
    }
`;

const StyledExceptionsPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

    .topBtn {
        border: 1px solid black !important;
        color: #000000 !important;
        border-radius: 8px;
        padding: 8px 15px !important;
        height: auto;
    }

    .adm-button {
        &:not(.adm-button-fill-none) {
            background: #26a59a !important;
            border-color: #26a59a;
        }

        &.adm-button-fill-none {
            color: #26a59a;
        }
    }

    .adm-selector-item-active {
        color: #26a59a;
        background-color: #c7e8e5;
    }

    .adm-selector-check-mark-wrapper {
        border-bottom: solid 8px #26a59a;
        border-right: solid 10px #26a59a;
    }

    .ant-table-thead {
        .ant-table-cell {
            &:nth-last-child(2) {
                &::before {
                    display: none;
                }
            }
        }
    }

    h1 {
        color: white;
        font-weight: 600;
    }

    > .ant-btn {
        margin-bottom: 20px;
    }

    .bigSvg {
        color: #26a59a;

        svg {
            width: 28px;
            height: 28px;
        }
    }
`;

const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};
// const defaultData: DataSourceType[] = [
//     {
//         id: 624748504,
//         title: '活动名称一',
//         readonly: '活动名称一',
//         decs: '这个活动真好玩',
//         state: 'open',
//         created_at: 1590486176000,
//         update_at: 1590486176000,
//     },
//     {
//         id: 624691229,
//         title: 'Activity name two',
//         readonly: 'Activity name two',
//         decs: 'This activity is so fun',
//         state: 'closed',
//         created_at: 1590481162000,
//         update_at: 1590481162000,
//     },
// ];

function ExceptionsPage() {
    const pools = useAppSelector((state) => state.page.pools);
    const status = useAppSelector((state) => state.page.status);
    const {name} = useParams();

    const statusItem = useMemo(() => {
        return status.filter(el => el.Name === name)[0]
    }, [status, name]);


    const elementsRef = useRef(pools.map(() => createRef<ProFormInstance>()));
    const elementsRef2 = useRef(pools.map(() => createRef<ProFormInstance>()));

    useEffect(() => {
        elementsRef2.current = pools.map(() => createRef<ProFormInstance>())
    }, [pools]);

    const dispatch = useAppDispatch();
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const [position, setPosition] = useState<'top' | 'bottom' | 'hidden'>(
        'top',
    );

    // const [formRef] = useForm();

    const formRef = useRef<ProFormInstance>();
    const formRef2 = useRef<ProFormInstance>();
    const ref = useRef<ActionType>();

    const [createFormRef] = Form.useForm();
    const [editFormRef] = Form.useForm();

    const [editableId, setEditableId] = useState<number | null>(null);

    useEffect(() => {
        if (editableId === null) {
            editFormRef.resetFields();
        } else {
            setEditModalVisible(true);
            editFormRef.setFieldsValue({
                ...statusItem?.Presets[editableId],
                Percent: statusItem?.Presets[editableId].Percent * 100
            })
        }
    }, [editableId]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getStatusAction())
        }, 1500)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const createExceptionForm = (onFinish?: (fields: StatusType['Presets'][number]) => void, ref?: any) => <Form
        requiredMarkStyle={"none"}
        initialValues={{
            Percent: 0
        }} form={ref}
        onFinish={onFinish}
        layout='horizontal'
        mode='card'>
        <Form.Item rules={[{
            required: true,
            message: "The 'Username' field is required."
        }]} name={"Username"}>
            <Input placeholder={"Worker"}/>
        </Form.Item>
        <Space>
            <Form.Item label={"MyFee"} rules={[{
                required: true,
                message: "The 'Percent' field is required."
            }]} name={"Percent"}>
                <InputNumber
                    precision={2}
                    // value={inputValue}
                    // onChange={(value: any) => setInputValue(value || 0)}
                    min={0} max={100}/>
            </Form.Item>
            %
        </Space>
    </Form>

    const columns: ProColumns<StatusType['Presets'][number] & { id: number }>[] = [
        {
            title: 'Worker',
            dataIndex: 'Username',
            ellipsis: true,
            width: "70%"
        },
        {
            title: 'MyFee',
            dataIndex: 'Percent',
            ellipsis: true,
            render: (text, record, _, action) => <span>{((record.Percent || 0) * 100).toFixed(2)}%</span>,
            width: "30%"
        },
        {
            title: '',
            width: 90,
            valueType: 'option',
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        setEditableId(record.id!);
                        // setEditModalVisible(true);
                        // editFormRef.setFieldsValue({...pools[record.id!], Type: [pools[record.id!]?.Type]})
                        // editFormRef.setFieldsValue(pools[record.id!])
                        // action?.startEditable?.(record.id!);
                    }}
                >
                    <EditOutlined className={"bigSvg"}/>
                </a>,
                <a
                    key="delete"
                    onClick={async () => {
                        Modal.confirm({
                            title: 'Confirm action',
                            content: 'Are you sure you want to delete this exception?',
                            getContainer: () => document.querySelector(`.${StyledExceptionsPage.styledComponentId}`)!,
                            confirmText: "Delete",
                            cancelText: "Cancel",
                            onConfirm: async () => await dispatch(changeExceptionAction({
                                name: name!,
                                presets: statusItem?.Presets?.filter((el, index) => index !== record.id)
                            })).unwrap().finally(() => {
                                return Promise.resolve(true)
                            })
                        })

                    }}
                >
                    <DeleteOutlined className={"bigSvg"}/>
                </a>,
            ],
        }
    ];

    useEffect(() => {
        dispatch(getStatusAction())
    }, []);

    const [modalVisible, setModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)

    return (
        <StyledExceptionsPage>
            <Modal
                visible={modalVisible}
                content={createExceptionForm((fields) => {
                    dispatch(changeExceptionAction({
                        name: name!,
                        presets: [...statusItem?.Presets, {...fields, Percent: fields.Percent / 100}]
                    })).unwrap().then(() => {
                        createFormRef.resetFields();
                        setModalVisible(false)
                    }).finally(() => {
                        return Promise.resolve(true)
                    })

                    // console.log({...fields, Percent: fields.Percent / 100});
                }, createFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setModalVisible(false)
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Add',
                        onClick: () => createFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Cancel',
                        onClick: () => setModalVisible(false)
                    },
                ]}/>
            <Modal
                visible={editModalVisible}
                content={createExceptionForm((fields) => {
                    let newArray = [...statusItem?.Presets?.filter((el, index) => index !== editableId)];
                    newArray.splice(editableId!, 0, {
                        ...fields,
                        Percent: fields.Percent / 100
                    });

                    dispatch(changeExceptionAction({
                        name: name!,
                        presets: newArray
                    })).unwrap().then(() => {
                        setEditModalVisible(false)
                        setEditableId(null);
                    }).finally(() => {
                        return Promise.resolve(true)
                    })


                }, editFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setEditModalVisible(false)
                    setEditableId(null);
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Save',
                        onClick: () => editFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Cancel',
                        onClick: () => {
                            setEditModalVisible(false)
                            setEditableId(null);
                        }
                    },
                ]}/>
            {/*<Button onClick={() => {*/}

            {/*    // console.log(formRef2.current);*/}
            {/*    // console.log(formRef.current);*/}
            {/*    // console.log("formRef2", formRef2.current?.getFieldsValue());*/}
            {/*    // console.log("formRef", formRef.current?.getFieldsValue());*/}
            {/*    // console.log("elementsRef2", elementsRef2.current);*/}
            {/*    //*/}
            {/*    // dispatch(getPoolsAction());*/}
            {/*    //*/}
            {/*    // return*/}

            {/*    Modal.confirm({*/}
            {/*        content: createPoolForm(undefined, createFormRef),*/}
            {/*        closeOnMaskClick: true,*/}
            {/*        confirmText: "Добавить",*/}
            {/*        cancelText: "Отмена",*/}
            {/*        onConfirm: async () => {*/}
            {/*            await createFormRef.validateFields().then(() => {*/}
            {/*                createFormRef.submit();*/}
            {/*            }).catch(() => {*/}
            {/*                return Promise.reject("123")*/}
            {/*            })*/}

            {/*            // await sleep(3000)*/}
            {/*            // Toast.show({*/}
            {/*            //     icon: 'success',*/}
            {/*            //     content: '提交成功',*/}
            {/*            //     position: 'bottom',*/}
            {/*            // })*/}
            {/*        }*/}
            {/*    }).catch(() => {*/}
            {/*    })*/}
            {/*}}>Добавить</Button>*/}
            <h1>Exceptions</h1>
            <Wrap>
                <Button className={"topBtn"} onClick={() => setModalVisible(true)}>Add Exception</Button>
                <EditableProTable<any>
                    rowKey="id"
                    scroll={{
                        x: '100%',
                    }}
                    recordCreatorProps={false}
                    // recordCreatorProps={{
                    //     position: 'top',
                    //     creatorButtonText: "Добавить",
                    //     record: {id: pools.length, Login: "", Password: "", Address: "", Type: "btc"}
                    // }}
                    editableFormRef={formRef}
                    loading={false}
                    columns={columns}
                    // request={async () => ({
                    //     data: pools.map((el, index) => ({...el, id: index})),
                    //     success: true,
                    // })}
                    // expandable={{expandedRowRender}}
                    value={statusItem?.Presets?.map(((el, index) => ({...el, id: index})))}
                    // onChange={setDataSource}
                    editable={{
                        type: 'single',
                        saveText: <SaveOutlined className={"bigSvg"}/>,
                        deleteText: <DeleteOutlined className={"bigSvg"}/>,
                        cancelText: <CloseOutlined className={"bigSvg"}/>,
                        onlyAddOneLineAlertMessage: "Добавьте только одну строку",
                        onlyOneLineEditorAlertMessage: "Одновременно можно редактировать только одну строку",
                        editableKeys,
                        onSave: async (rowKey, data, row) => {
                            if (rowKey !== pools.length) {
                                const newArray = [...pools.filter((el, index) => index !== rowKey)];
                                // @ts-ignore
                                newArray.splice(rowKey, 0, data)
                                await dispatch(changePoolsAction(newArray)).unwrap().finally(() => {
                                    return Promise.resolve(true)
                                })
                            } else {
                                const {id, index, ...rest} = data;
                                await dispatch(changePoolsAction([...pools, rest])).unwrap().finally(() => {
                                    return Promise.resolve(true)
                                })
                            }
                            // await waitTime()
                        },
                        onChange: setEditableRowKeys,
                    }}
                />
            </Wrap>
        </StyledExceptionsPage>
    );
}

export default ExceptionsPage;