import React from 'react';
import styled from "styled-components";
import LogoHalfSVG from "../images/LogoHalfSVG";
import {Link, useLocation} from "react-router-dom";
import {api} from "../config";
import {Button, Space} from "antd";

const StyledHeader = styled.div`
    display: flex;
    background: #fafafa;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    justify-content: space-between;

    a {
        display: inline-flex;
    }

    svg {
        width: 120px;
        height: auto;
    }

    .ant-btn {
        border: none;
        background: #26a59a !important;
        width: 90px;

        span {
            color: white;
        }
    }
`;

function Header() {
    const location = useLocation()
    const {pathname} = location

    return (
        <StyledHeader>
            <Link to={"/"}>
                <LogoHalfSVG/>
            </Link>
            <Space size={10}>
                {pathname.includes("/pools") ? <Link to={"/"}>
                    <Button>My agents</Button>
                </Link> : <Link to={"/pools"}>
                    <Button>My pools</Button>
                </Link>}
                <Link to={"#"} onClick={() => {
                    window.location.href = `${api}/exit`;
                }}>
                    <Button>Exit</Button>
                </Link>
            </Space>
        </StyledHeader>
    );
}

export default Header;