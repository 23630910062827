import React, {createRef, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {changePoolsAction, getPoolsAction} from "../redux/pageSlice";
import {ActionType, EditableProTable, ProColumns, ProFormInstance,} from '@ant-design/pro-components';
import {CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {PoolType} from "../utils/types";
import {Form, Input, Modal, Selector} from "antd-mobile";
import {Button} from "antd";

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #fafafa;
    padding: 20px 0;
    border-radius: 12px;

    .ant-btn {
        margin: 0 20px;
    }
`;

const StyledPoolsPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

    .topBtn {
        border: 1px solid black !important;
        color: #000000 !important;
        border-radius: 8px;
        padding: 8px 15px !important;
        height: auto;
    }

    .adm-button {
        &:not(.adm-button-fill-none) {
            background: #26a59a !important;
            border-color: #26a59a;
        }

        &.adm-button-fill-none {
            color: #26a59a;
        }
    }

    .adm-selector-item-active {
        color: #26a59a;
        background-color: #c7e8e5;
    }

    .adm-selector-check-mark-wrapper {
        border-bottom: solid 8px #26a59a;
        border-right: solid 10px #26a59a;
    }

    .ant-table-thead {
        .ant-table-cell {
            &:nth-last-child(2) {
                &::before {
                    display: none;
                }
            }
        }
    }

    h1 {
        color: white;
        font-weight: 600;
    }

    > .ant-btn {
        margin-bottom: 20px;
    }

    .bigSvg {
        color: #26a59a;

        svg {
            width: 28px;
            height: 28px;
        }
    }
`;

const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};
// const defaultData: DataSourceType[] = [
//     {
//         id: 624748504,
//         title: '活动名称一',
//         readonly: '活动名称一',
//         decs: '这个活动真好玩',
//         state: 'open',
//         created_at: 1590486176000,
//         update_at: 1590486176000,
//     },
//     {
//         id: 624691229,
//         title: 'Activity name two',
//         readonly: 'Activity name two',
//         decs: 'This activity is so fun',
//         state: 'closed',
//         created_at: 1590481162000,
//         update_at: 1590481162000,
//     },
// ];

function PoolsPage() {
    const pools = useAppSelector((state) => state.page.pools);

    const elementsRef = useRef(pools.map(() => createRef<ProFormInstance>()));
    const elementsRef2 = useRef(pools.map(() => createRef<ProFormInstance>()));

    useEffect(() => {
        elementsRef2.current = pools.map(() => createRef<ProFormInstance>())
    }, [pools]);

    const dispatch = useAppDispatch();
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const [position, setPosition] = useState<'top' | 'bottom' | 'hidden'>(
        'top',
    );

    // const [formRef] = useForm();

    const formRef = useRef<ProFormInstance>();
    const formRef2 = useRef<ProFormInstance>();
    const ref = useRef<ActionType>();

    const [createFormRef] = Form.useForm();
    const [editFormRef] = Form.useForm();

    const [editableId, setEditableId] = useState<number | null>(null);

    useEffect(() => {
        if (editableId === null) {
            editFormRef.resetFields();
        } else {
            setEditModalVisible(true);
            editFormRef.setFieldsValue({...pools[editableId], Type: [pools[editableId]?.Type]})
        }
    }, [editableId]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getPoolsAction())
        }, 1500)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const createPoolForm = (onFinish?: (fields: PoolType) => void, ref?: any) => <Form initialValues={{
        Type: ['btc']
    }} form={ref} onFinish={onFinish} layout='horizontal' mode='card'>
        <Form.Item rules={[{
            required: true,
            message: "The 'Address' field is required."
        }]} name={"Address"}>
            <Input placeholder={"Address"}/>
        </Form.Item>
        <Form.Item name={"Login"}>
            <Input placeholder={"Login"}/>
        </Form.Item>
        <Form.Item name={"Password"}>
            <Input placeholder={"Password"}/>
        </Form.Item>
        <Form.Header>Type</Form.Header>
        <Form.Item rules={[{
            required: true,
            message: "The 'Type' field is required."
        }]} name={"Type"}>
            <Selector
                columns={3}
                options={[
                    {label: 'btc', value: 'btc'},
                    {label: 'kas', value: 'kas'},
                    {label: 'ltc', value: 'ltc'},
                ]}
            />
        </Form.Item>
    </Form>

    const columns: ProColumns<PoolType>[] = [
        {
            title: 'Worker',
            dataIndex: 'Login',
            ellipsis: true
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            ellipsis: true
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            valueType: 'select',
            width: 70,
            valueEnum: {
                mockup: {text: 'mockup', disabled: true},
                btc: {text: 'btc'},
                kas: {text: 'kas'},
                ltc: {text: 'ltc'},
            },
        },
        {
            title: '',
            width: 90,
            valueType: 'option',
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        setEditableId(record.id!);
                        // setEditModalVisible(true);
                        // editFormRef.setFieldsValue({...pools[record.id!], Type: [pools[record.id!]?.Type]})
                        // editFormRef.setFieldsValue(pools[record.id!])
                        // action?.startEditable?.(record.id!);
                    }}
                >
                    <EditOutlined className={"bigSvg"}/>
                </a>,
                <a
                    key="delete"
                    onClick={async () => {
                        Modal.confirm({
                            title: 'Confirm action',
                            content: 'Are you sure you want to delete this pool?',
                            getContainer: () => document.querySelector(`.${StyledPoolsPage.styledComponentId}`)!,
                            confirmText: "Delete",
                            cancelText: "Cancel",
                            onConfirm: async () => await dispatch(changePoolsAction(pools.filter((el, index) => index !== record.id))).unwrap().finally(() => {
                                return Promise.resolve(true)
                            })
                        })

                    }}
                >
                    <DeleteOutlined className={"bigSvg"}/>
                </a>,
            ],
        }
    ];
    // const nestedColumns: ProColumns<PoolType>[] = [
    //     {
    //         title: 'Логин',
    //         dataIndex: 'Login',
    //     },
    //     {
    //         title: 'Пароль',
    //         dataIndex: 'Password',
    //         valueType: "password"
    //     },
    //     {
    //         title: '',
    //         valueType: 'option',
    //         render: (text, record, _, action) => [
    //             <a
    //                 key="editable"
    //                 onClick={() => {
    //                     setEditableId(record.id!);
    //                     // setEditModalVisible(true);
    //                     // editFormRef.setFieldsValue({...pools[record.id!], Type: [pools[record.id!]?.Type]})
    //                     // editFormRef.setFieldsValue(pools[record.id!])
    //                     // action?.startEditable?.(record.id!);
    //                 }}
    //             >
    //                 <EditOutlined className={"bigSvg"}/>
    //             </a>,
    //             <a
    //                 key="delete"
    //                 onClick={async () => {
    //                     await dispatch(changePoolsAction(pools.filter((el, index) => index !== record.id))).unwrap().finally(() => {
    //                         return Promise.resolve(true)
    //                     })
    //                 }}
    //             >
    //                 <DeleteOutlined className={"bigSvg"}/>
    //             </a>,
    //         ],
    //     },
    // ];
    useEffect(() => {
        dispatch(getPoolsAction())
    }, []);


    // const expandedRowRender = (record: any) => {
    //     return <EditableProTable<PoolType>
    //         rowKey="id"
    //         scroll={{
    //             x: '100%',
    //         }}
    //         recordCreatorProps={false}
    //         loading={false}
    //         columns={nestedColumns}
    //         // request={async () => ({
    //         //     data: pools.map((el, index) => ({...el, id: index})).filter(el => el.id === record.id),
    //         //     success: true,
    //         // })}
    //         value={pools.map((el, index) => ({...el, id: index})).filter(el => el.id === record.id)}
    //         // onChange={setDataSource}
    //         editableFormRef={elementsRef2.current[record.id]}
    //         editable={{
    //             formProps: {},
    //             type: 'single',
    //             saveText: <SaveOutlined className={"bigSvg"}/>,
    //             deleteText: <DeleteOutlined className={"bigSvg"}/>,
    //             cancelText: <CloseOutlined className={"bigSvg"}/>,
    //             onlyAddOneLineAlertMessage: "Добавьте только одну строку",
    //             onlyOneLineEditorAlertMessage: "Одновременно можно редактировать только одну строку",
    //             editableKeys,
    //             // onSave: async (rowKey, data, row) => {
    //             //     console.log(formRef2.current?.getFieldsValue());
    //             //     console.log(formRef.current?.getFieldsValue());
    //             //     console.log(elementsRef2.current[record.id]?.current?.getFieldsValue());
    //             //     console.log({id: rowKey, ...elementsRef2.current[record.id]?.current?.getFieldsValue()[record.id], ...formRef.current?.getFieldsValue()[record.id]})
    //             //     return
    //             //
    //             //     // @ts-ignore
    //             //     const newPool = {id: rowKey, ...formRef2.current?.getFieldsValue()[rowKey], ...formRef.current?.getFieldsValue()[rowKey]}
    //             //     console.log("newPool", newPool);
    //             //     return
    //             //     if (rowKey !== pools.length) {
    //             //         const newArray = [...pools.filter((el, index) => index !== rowKey)];
    //             //         // @ts-ignore
    //             //         newArray.splice(rowKey, 0, newPool)
    //             //         console.log(newArray)
    //             //
    //             //         await dispatch(changePoolsAction(newArray)).unwrap().finally(() => {
    //             //             console.log("resolved");
    //             //             return Promise.resolve(true)
    //             //         })
    //             //     } else {
    //             //         console.log(rowKey, data, row);
    //             //
    //             //         const {id, index, ...rest} = newPool;
    //             //
    //             //         console.log([...pools, rest])
    //             //         await dispatch(changePoolsAction([...pools, rest])).unwrap().finally(() => {
    //             //             console.log("resolved");
    //             //             return Promise.resolve(true)
    //             //         })
    //             //     }
    //             //     // await waitTime()
    //             // },
    //             onChange: setEditableRowKeys,
    //         }}
    //     />
    // }

    const [modalVisible, setModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)

    return (
        <StyledPoolsPage>
            <Modal
                visible={modalVisible}
                content={createPoolForm((fields) => {
                    dispatch(changePoolsAction([...pools, {
                        ...fields,
                        Type: fields.Type?.[0]
                    } as PoolType])).unwrap().then(() => {
                        createFormRef.resetFields();
                        setModalVisible(false)
                    }).finally(() => {
                        return Promise.resolve(true)
                    })
                }, createFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setModalVisible(false)
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Add',
                        onClick: () => createFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Cancel',
                        onClick: () => setModalVisible(false)
                    },
                ]}/>
            <Modal
                visible={editModalVisible}
                content={createPoolForm((fields) => {

                    const newArray = [...pools.filter((el, index) => index !== editableId)];
                    newArray.splice(editableId!, 0, {
                        ...fields,
                        Type: fields.Type?.[0] as PoolType['Type']
                    })

                    dispatch(changePoolsAction(newArray)).unwrap().then(() => {
                        setEditModalVisible(false)
                        setEditableId(null);
                    }).finally(() => {
                        return Promise.resolve(true)
                    })

                }, editFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setEditModalVisible(false)
                    setEditableId(null);
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Save',
                        onClick: () => editFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Cancel',
                        onClick: () => {
                            setEditModalVisible(false)
                            setEditableId(null);
                        }
                    },
                ]}/>
            {/*<Button onClick={() => {*/}

            {/*    // console.log(formRef2.current);*/}
            {/*    // console.log(formRef.current);*/}
            {/*    // console.log("formRef2", formRef2.current?.getFieldsValue());*/}
            {/*    // console.log("formRef", formRef.current?.getFieldsValue());*/}
            {/*    // console.log("elementsRef2", elementsRef2.current);*/}
            {/*    //*/}
            {/*    // dispatch(getPoolsAction());*/}
            {/*    //*/}
            {/*    // return*/}

            {/*    Modal.confirm({*/}
            {/*        content: createPoolForm(undefined, createFormRef),*/}
            {/*        closeOnMaskClick: true,*/}
            {/*        confirmText: "Добавить",*/}
            {/*        cancelText: "Отмена",*/}
            {/*        onConfirm: async () => {*/}
            {/*            await createFormRef.validateFields().then(() => {*/}
            {/*                createFormRef.submit();*/}
            {/*            }).catch(() => {*/}
            {/*                return Promise.reject("123")*/}
            {/*            })*/}

            {/*            // await sleep(3000)*/}
            {/*            // Toast.show({*/}
            {/*            //     icon: 'success',*/}
            {/*            //     content: '提交成功',*/}
            {/*            //     position: 'bottom',*/}
            {/*            // })*/}
            {/*        }*/}
            {/*    }).catch(() => {*/}
            {/*    })*/}
            {/*}}>Добавить</Button>*/}
            <h1>My pools</h1>
            <Wrap>
                <Button className={"topBtn"} onClick={() => setModalVisible(true)}>Add New Pool</Button>
                <EditableProTable<PoolType>
                    rowKey="id"
                    scroll={{
                        x: '100%',
                    }}
                    recordCreatorProps={false}
                    // recordCreatorProps={{
                    //     position: 'top',
                    //     creatorButtonText: "Добавить",
                    //     record: {id: pools.length, Login: "", Password: "", Address: "", Type: "btc"}
                    // }}
                    editableFormRef={formRef}
                    loading={false}
                    columns={columns}
                    // request={async () => ({
                    //     data: pools.map((el, index) => ({...el, id: index})),
                    //     success: true,
                    // })}
                    // expandable={{expandedRowRender}}
                    value={pools.map((el, index) => ({...el, id: index}))}
                    // onChange={setDataSource}
                    editable={{
                        type: 'single',
                        saveText: <SaveOutlined className={"bigSvg"}/>,
                        deleteText: <DeleteOutlined className={"bigSvg"}/>,
                        cancelText: <CloseOutlined className={"bigSvg"}/>,
                        onlyAddOneLineAlertMessage: "Добавьте только одну строку",
                        onlyOneLineEditorAlertMessage: "Одновременно можно редактировать только одну строку",
                        editableKeys,
                        onSave: async (rowKey, data, row) => {
                            if (rowKey !== pools.length) {
                                const newArray = [...pools.filter((el, index) => index !== rowKey)];
                                // @ts-ignore
                                newArray.splice(rowKey, 0, data)
                                await dispatch(changePoolsAction(newArray)).unwrap().finally(() => {
                                    return Promise.resolve(true)
                                })
                            } else {
                                const {id, index, ...rest} = data;
                                await dispatch(changePoolsAction([...pools, rest])).unwrap().finally(() => {
                                    return Promise.resolve(true)
                                })
                            }
                            // await waitTime()
                        },
                        onChange: setEditableRowKeys,
                    }}
                />
            </Wrap>
        </StyledPoolsPage>
    );
}

export default PoolsPage;